<script>
import { mapActions } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import { differenceInDays } from 'date-fns'
import reportsMixin from '@/modules/Reports/reportsMixin'

export default {
  name: 'ReportEventsList',

  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ reportsMixin ],

  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: '',
        groups: [],
        reportId: null
      },

      groups: [],
      modalWarning: false
    }
  },

  validations: {
    formData: {
      dateInit: {
        required: requiredIf(function () {
          return !this.formData.dateEnd
        }),

        dateAfter: function (event) {
          return (this.formData.dateInit && this.formData.dateEnd) ? this.isDateAfter(new Date(event), new Date(this.formData.dateEnd)) : true
        }
      },

      dateEnd: {
        required: requiredIf(function () {
          return !this.formData.dateInit
        }),

        dateBefore: function (event) {
          return (this.formData.dateInit && this.formData.dateEnd) ? this.isDateBefore(new Date(this.formData.dateInit), new Date(event)) : true
        }
      }
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([ this.getGroupsList() ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetGroupsList',
      'attemptGetReportEventsList',
      'attemptCheckReport',
      'attemptReportDownload'
    ]),

    getReportEventsList () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const initDate = new Date(this.formData.dateInit)
      const finalDate = new Date(this.formData.dateEnd)

      if (differenceInDays(finalDate, initDate) > 31) {
        this.modalWarning = true
      } else {
        this.generateReport()
      }
    },

    generateReport () {
      this.modalWarning = false
      const params = {
        createdDateInit: this.formData.dateInit,
        createdDateEnd: this.formData.dateEnd,
        groupIds: this.formData.groups
      }

      this.setFetching(true)

      this.attemptGetReportEventsList(params).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFetching(false)
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    getGroupsList () {
      return this.attemptGetGroupsList({
        filter: {
          active: true
        },
        order: {
          name: 'ASC'
        },
        limit: 9999
      }).then(pagination => {
        if (pagination.data) {
          this.groups = pagination.data.map((element) => {
            return {
              text: element.name,
              value: element.id
            }
          })
        }
      }).catch((e) => {
        console.log(e)
      })
    },

    closeModal () {
      this.modalWarning = false
    },

    leave () {
      this.$router.push({ name: 'reports.index' })
    },

    downloadReport () {
      this.attemptReportDownload(this.reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]))
        const link = document.createElement('a')

        link.href = url

        link.setAttribute(
          'download',
          this.$t('reports.generate:report.enrollments.programs.list.file.name').toString()
        )

        document.body.appendChild(link)
        link.click()
      })
    },

    checkReport () {
      const interval = setInterval(() => {
        this.attemptCheckReport(this.reportId).then((response) => {
          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            clearInterval(interval)
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            clearInterval(interval)
          }
        })
      }, 5000)
    }
  }
}
</script>

<template>
  <div class="main-content reports-create reports-events">
    <ContentHeader
      :title="$t('reports.generate:report.enrollment.event.list.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>

    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>

        <form class="form">
          <FormSection :title="$t('global:form.filters')">
            <div>
              <Datepicker
                v-model="formData.dateInit"
                :label="$t('global:form.filters.date.init')"
                :validation="$v.formData.dateInit"
              />

              <Datepicker
                v-model="formData.dateEnd"
                :label="$t('global:form.filters.date.end')"
                :validation="$v.formData.dateEnd"
              />

              <SelectField
                v-model="formData.groups"
                :label="$t('global:form.groups')"
                :items="groups"
                multiple
                :searcheable="true"
              />
            </div>
          </FormSection>

          <Action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportEventsList"
          />
        </form>
      </div>
    </div>

    <ModalConfirm
      :active="modalWarning"
      :title="$t('reports:report.enrollment.event.list.title')"
      :description="$t('reports.generate:report.modal.description')"
      @cancelAction="closeModal"
      @confirmAction="generateReport"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>
